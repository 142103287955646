<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-subheader>Selecciona un cliente para editar sus tablas de datos</v-subheader>
                    <v-data-table
                        :headers="headers"
                        :items="townHalls"
                        sort-by="calories"
                        class="elevation-1">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-toolbar-title>Graficos por clientes</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                        </template>
                        <template v-slot:no-data>
                            No se encontraron registros.
                        </template>
                       <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="viewItem(item.actions)"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data: () => ({
        headers: [
        {
          text: 'Nombre cliente',
          align: 'start',
          value: 'name_townhall',
        },
        { text: 'E-Mail', value: 'email' },
        { text: 'Url', value: 'url'},
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      halls:[],
      townHalls: [],
    }),
    methods: {
        createCliente(){
            this.$router.push({ name: 'CreateClient' });
            
        }
        ,viewItem(id){
            console.log('holiii')
            this.$router.push({ path: 'Townhall/'+id });
        }
    },
    
    beforeMount(){

        console.log('this.townHalls', this.townHalls)

        this.$http.get('townhalls')
        //this.$http.get('townhalls',{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                .then(response => {
                    this.halls = response.body
                    ////console.log(this.halls)
                    for(var i = 0;i < this.halls.length ; i++){
                        const name_townhall = this.halls[i].name_townhall
                        const email = this.halls[i].email
                        const url = this.halls[i].url
                        const actions = this.halls[i].id 
                        const obj = {
                            name_townhall:name_townhall,
                            email:email,
                            url:url,
                            actions,actions
                        }
                        this.townHalls.push(obj)
                    }

                    })
        
    }
}
</script>